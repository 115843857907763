/* src/components/Navbar.css */

/* Base Navbar Styles */
.navbar {
  display: flex;
  align-items: center;
  background-color: #000000;
  padding: 10px 20px;
  position: relative;
  z-index: 1000;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  height: 50px;
}

.nav-links {
  margin-left: auto;
}

.nav-links ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  color: #FFFFFF;
  font-size: 18px;
  font-family: 'Lora', serif;
  text-decoration: none;
}

.nav-links a:hover {
  color: #C5B358;
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  flex-direction: column;
}

.menu-icon-bar {
  width: 25px;
  height: 3px;
  background-color: #FFFFFF;
  margin: 4px 0;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 70px; /* Adjust based on navbar height */
    left: 0;
    width: 100%;
    background-color: #000000;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  }

  .nav-links.mobile-menu-open {
    max-height: 500px; /* Enough to show all links */
    transition: max-height 0.3s ease-in;
  }

  .nav-links ul {
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .mobile-menu-icon {
    display: flex;
  }

  .nav-links {
    margin-left: 0;
  }
}

/* Optional: Add a slight background overlay when mobile menu is open */
@media (max-width: 768px) {
  body.mobile-menu-open {
    overflow: hidden;
  }
}
