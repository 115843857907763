/* src/pages/Home.css */

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 20px 20px 20px; /* Top padding adjusted for navbar */
}

.home-logo {
  max-width: 300px;
  width: 100%;
  height: auto;
  border: none;
  margin-top: 20px;
}

.home-title {
  margin-top: 20px;
  font-size: 48px;
  font-family: 'Playfair Display', serif;
  color: #C5B358; /* Vegas gold color */
  text-align: center;
}

@media (max-width: 768px) {
  .home-logo {
    max-width: 80%;
  }

  .home-title {
    font-size: 36px;
  }
}
